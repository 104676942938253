import { Location } from 'history';
import { useSelector } from 'react-redux';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';

interface MenuControls {
  close: () => any;
  toggle: (e: SyntheticEvent) => any;
  isOpen: boolean;
  ref: MutableRefObject<any>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const useMenuControls = (): MenuControls => {
  const [isOpen, setOpen] = useState(false);
  const location = useSelector<NavCarrierState, Location>(state => state.router.location);

  const toggle = useCallback((e: SyntheticEvent) => {
    setOpen(state => !state);
  }, []);
  const close = useCallback(() => isOpen && setOpen(false), [isOpen]);

  const ref = useRef(null);
  useOnOutsideClick(ref, close);

  useEffect(() => { close(); }, [location, location?.pathname]);

  return useMemo(() => ({
    isOpen,
    setOpen,
    ref,
    toggle,
    close
  }), [
    isOpen,
    ref
  ]);
};
